import React from 'react';

const Feather = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="inherit"
    height="inherit"
    viewBox="0 0 57.458 90.912"
  >
    <defs>
      <clipPath id="clip-path-feather">
        <path id="Tracé_813" data-name="Tracé 813" d="M0,0H57.458V-90.912H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Picto_peaux_sensibles" data-name="Picto peaux sensibles" transform="translate(0 90.912)">
      <g id="Groupe_1038" data-name="Groupe 1038" clipPath="url(#clip-path-feather)">
        <g id="Groupe_1036" data-name="Groupe 1036" transform="translate(57.394 -73.322)">
          <path
            id="Tracé_811"
            data-name="Tracé 811"
            d="M0,0A28.588,28.588,0,0,0-7.475-17.059a1.8,1.8,0,0,0-1.258-.531,1.622,1.622,0,0,0-1.215.629c-4.491,5.873-10.915,10.2-17.127,14.376C-33.6,1.8-40.345,6.345-45.166,12.784a14.03,14.03,0,0,0-2.335,4.28,15.314,15.314,0,0,0-.452,4.57,73.416,73.416,0,0,0,.614,8.3l-2.59-5.289a1.6,1.6,0,0,0-1.445-.9,1.536,1.536,0,0,0-1.45.919c-.127.265-.27.526-.412.791l-.33.62c-.015.024-.182.383-.2.413l-.2.432a20.719,20.719,0,0,0-.786,2.035l-.172.5a51.233,51.233,0,0,0-1.509,5.028,32.679,32.679,0,0,0-.963,7.755,20.755,20.755,0,0,0,5.976,15.1,4.191,4.191,0,0,1,.487.517,2.659,2.659,0,0,1-.128.7l-3.156,12.233A1.614,1.614,0,0,0-53.1,72.738l1.7.526a1.674,1.674,0,0,0,.432.058h.007a1.6,1.6,0,0,0,1.541-1.159c1.145-3.951,2.512-7.932,4.06-11.83a2.606,2.606,0,0,1,.437-.811,3.391,3.391,0,0,1,1.357-.491A51.863,51.863,0,0,0-7.981,27.552,1.6,1.6,0,0,0-8.2,26.014a1.553,1.553,0,0,0-1.425-.634l-7.785.7-2.442.314c4.673-1.961,10.065-4.467,13.928-8.679A23.8,23.8,0,0,0,0,0M-22.677,24.087a4.241,4.241,0,0,0-2.555,2.679,2.356,2.356,0,0,0,.594,2.133,3.121,3.121,0,0,0,2.743.81l9.913-.889A48.732,48.732,0,0,1-44.4,55.93a5.356,5.356,0,0,0-2.84,1.361,4.073,4.073,0,0,0-.482.59,3.338,3.338,0,0,0-.128-.929,4.672,4.672,0,0,0-1.445-2.03c-3.091-2.944-4.875-7.568-4.89-12.695a29.459,29.459,0,0,1,.875-6.993,47.071,47.071,0,0,1,1.415-4.718l.182-.521c.113-.325.221-.649.344-.973l2.551,5.209a2.919,2.919,0,0,0,1.671,1.691,2.3,2.3,0,0,0,2.28-.408,4.125,4.125,0,0,0,1.052-3.671,70.816,70.816,0,0,1-.929-10.252,12.544,12.544,0,0,1,.319-3.608,11.03,11.03,0,0,1,1.829-3.273C-38.1,8.7-31.872,4.517-25.281.084c5.888-3.962,11.962-8.051,16.636-13.545A25.048,25.048,0,0,1-3.2.221,20.883,20.883,0,0,1-8.3,15.545c-3.824,4.168-9.427,6.493-14.376,8.542"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1037" data-name="Groupe 1037" transform="translate(45.599 -74.148)">
          <path
            id="Tracé_812"
            data-name="Tracé 812"
            d="M0,0A3,3,0,0,0-1.8,1.41,130.19,130.19,0,0,1-13.958,16.592c-1.111,1.253-2.227,2.512-3.322,3.77-.453.516-.9,1.036-1.352,1.567l-.57.664c-.507.594-1.017,1.194-1.509,1.8l-.275.34c-.472.595-.954,1.189-1.406,1.779-.55.717-1.1,1.445-1.637,2.172s-1.041,1.455-1.548,2.192-1.022,1.509-1.489,2.275-.924,1.553-1.371,2.335-.85,1.587-1.253,2.394c-.187.393-.566,1.144-.9,1.833-.379.757-.713,1.435-.738,1.5-.2.462-.388.934-.575,1.4l-.364.9c-.255.643-.51,1.278-.781,1.926q-.369.951-.762,1.9l-.373.913a4.057,4.057,0,0,0-.374,1.691,1.8,1.8,0,0,0,1.121,1.612l.275.079a1.882,1.882,0,0,0,.236.015c.86,0,1.587-.743,1.966-2.03,2.841-9.113,8.35-18.244,16.833-27.911,1.248-1.425,2.526-2.831,3.809-4.237,4.1-4.5,8.335-9.155,11.461-14.493C1.818,1.887,1.8.855,1.106.295A1.325,1.325,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Feather;
